import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, ToggleButton, Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import RangeSlider from 'react-bootstrap-range-slider';
import { ShimmerCarTextosLujo } from "./miscelaneos";

import Image from "./basico/image";

import api from "../../actions/riveroApi";

export default function CotizadorNuevo({landing, infoCar, marca, seguroSeleccionado }){

    const [ pagoMensual, setPagoMensual ] = useState();
    const [ cargando, setCargando ] = useState(false);

    const [ infoCotiza, setInfoCotiza ] = useState({
        id: infoCar.id,
        plazo: '60',
        enganche: ((infoCar.precio) / 100) * 25
    })

    const [ modalCotiza, setModalCotiza ] = useState(false);
    const [ modalSeguros, setModalSeguros ] = useState(false);

    useEffect(() => {
        setCargando(true)
        let obj = {
            car_id: infoCar.id,
            entry_percentage: 25,
            months: '60',
            warraty_id: 1,
        }

        api.createQuotation(obj).then((resp) => {
            setCargando(false)
            setPagoMensual(resp.data.payment.monthly_payment);
            //onSelectedPasos(object, calcularModal);
        }).catch((error) => console.log(error));
    }, []);

    function recalcularMensualidad (data){
        setCargando(true)
         
        setInfoCotiza({
            ...infoCotiza,
            id: data.id,
            plazo: data.plazo,
            enganche: data.enganche
        });

        let obj = {
            car_id: data.id,
            entry_percentage: data.porcentaje,
            months: data.plazo,
            warraty_id: 1,
        }

        api.createQuotation(obj).then((resp) => {
            setCargando(false)
            setPagoMensual(resp.data.payment.monthly_payment);
            //onSelectedPasos(object, calcularModal);
        }).catch((error) => console.log(error));

    }

    return(
        <>
            <Table width="100%">
                
                {marca == "NISSAN" ? (
                    <>
                    <tbody className={landing  ? "textcolor-Tabla-LandingLujo" : "text-white"}>
                        <tr>
                            <td style={{ width: "90px"}}>
                                <strong>Modelo</strong>
                            </td>
                            <td style={{ textTransform: "uppercase" }}>
                            {infoCar?.modelo} {infoCar?.anio} - {infoCar?.version}
                            </td>
                        </tr>

                        <tr>
                            <td>
                            <strong>Precio</strong>
                            </td>
                            <td>
                            <strong style={{ fontSize: "18px" }} className="modal-textoDetalle-3pasos">
                                $ <NumericFormat displayType="text" value={infoCar?.precio} allowLeadingZeros thousandSeparator="," decimalScale={2} /> MXN
                            </strong>
                            </td>
                        </tr>
                    </tbody>
                    </>
                ) : (
                <>
                <tbody className={landing  ? "textcolor-Tabla-LandingLujo" : "text-white"}>
                    <tr>
                        <td style={{ width: "90px"}}>
                            <strong>Modelo</strong>
                        </td>
                        <td style={{ textTransform: "uppercase" }}>
                        {infoCar?.modelo} {infoCar?.anio} - {infoCar?.version}
                        </td>
                    </tr>

                    <tr>
                        <td>
                        <strong>Precio</strong>
                        </td>
                        <td>
                        <strong style={{ fontSize: "18px" }} className="modal-textoDetalle-3pasos">
                            $ <NumericFormat displayType="text" value={infoCar?.precio} allowLeadingZeros thousandSeparator="," decimalScale={2} /> MXN
                        </strong>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ width: "90px" }}>
                        <strong>Plazo</strong>
                        </td>
                        <td>
                            <strong className="modal-textoDetalle-3pasos">
                                {cargando === true ? (
                                    <ShimmerCarTextosLujo width={'100%'} height={20} color="rgba(0, 0, 0, 0.15)"/>
                                ) 
                                :  
                                <>{infoCotiza?.plazo} meses</>
                                }
                            </strong>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        <strong>Enganche</strong>
                        </td>
                        <td>
                            <strong className="modal-textoDetalle-3pasos">
                                {cargando === true ? (
                                        <ShimmerCarTextosLujo width={'100%'} height={20} color="rgba(0, 0, 0, 0.15)"/>
                                    ) 
                                    :  
                                    <> $<NumericFormat displayType="text" value={infoCotiza?.enganche} allowLeadingZeros thousandSeparator="," decimalScale={2}/> MXN</>
                                }
                            </strong>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ verticalAlign: "middle"}}>
                            <strong>Seguros</strong>
                        </td>
                        <td >
                        <button className="btn btn-default p-0 d-flex w-100 align-items-start justify-start" onClick={() => setModalSeguros(true)} >
                            <div className="flex-grow-1 text-left" style={{color: 'white', textAlign: 'left'}}>
                                {seguroSeleccionado ? 
                                    <>
                                        {seguroSeleccionado[0]?.insuranceCompany}/{seguroSeleccionado[0]?.period} meses de 
                                        $<NumericFormat className="px-1" displayType="text" value={seguroSeleccionado[0]?.monthlyPrice} allowLeadingZeros thousandSeparator="," />MXN 
                                        (contado: $<NumericFormat className="px-1" displayType="text" value={seguroSeleccionado[0]?.price} allowLeadingZeros thousandSeparator="," />MXN)
                                    </>
                                    : null}
                            </div>
                            <div className="flex-grow-0" style={{ width: 24, height: 24 }}>
                                <Image
                                    fileName="iconos_pluma-azul.png"
                                    alt="editar carro"
                                    width={24}
                                />
                            </div>
                        </button>
                        </td>
                    </tr>
                </tbody>

                    </>

            )}
            </Table>

            {marca != "NISSAN" ? (  
                <>
            <Row>
                <Col>
                    {cargando === true ? (
                        <ShimmerCarTextosLujo width={'100%'} height={20} color="rgba(0, 0, 0, 0.15)"/>
                    ) : (
                        <center>
                            <p>Financiamiento a {infoCotiza?.plazo} meses con enganche de $<NumericFormat displayType="text" value={infoCotiza?.enganche} allowLeadingZeros thousandSeparator="," decimalScale={2}/> MXN</p>
                            <Col lg={6} sm={12}>
                                pago mensual de:
                            </Col>
                            <Col lg={6} sm={12}>
                                <h3 className="modal-precioMensual-3pasos">
                                    
                                        <>$<NumericFormat className="px-1" displayType="text" value={pagoMensual} allowLeadingZeros thousandSeparator="," />MXN</>
                    
                                </h3>
                            </Col>
                        </center>
                    ) }
                </Col>
            </Row>

            <Row>
                <Col lg={10} sm={10}>
                    <i className="text-right text-wrap" style={{ fontSize: "10px" }}>
                        *Planes sujetos a cambios sin previo aviso, crédito sujeto a
                        aprobación por financiera.
                        <br />
                        No incluye (Placas | Seguros), Planes SIN anualidades. Aplican
                        restricciones.
                    </i>
                </Col>

                <Col lg={2} sm={2}>
                    <div className="btn-editar-3pasos" role="button" onClick={() => setModalCotiza(true)}>
                        <Image
                            fileName="iconos_pluma-azul.png"
                            alt="editar carro"
                            width="100%"
                        />
                    </div>
                </Col>
            </Row>
            </>
            ) : (null)}


            <ModalCotizador show={modalCotiza} onHide={() => setModalCotiza(false)} infoCar={infoCar} recalcularMensualidad={recalcularMensualidad}/>
        </>
    )

}

export function ModalCotizador({show, onHide, infoCar, dataCar, recalcularMensualidad, calcularModal}){

    const [ plazos, setPlazos ] = useState("60");
    const [ value, setValue ] = useState(10);

    const meses = ["12", "24", "36", "48", "60"];

    const mappingVersionsSelect = dataCar?.versions.map((obj, i) => {
        return(
            <option key={i} value={obj.name+';'+obj.price+';'+obj.id}>{obj.name}</option>
        )
    });

    const mappingMeses = meses.map((obj, i) => {
        return(
            <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="radio"
                name="radio"
                value={obj}
                checked={plazos === obj}
                onChange={(e) => setPlazos(e.currentTarget.value)}
            >
                {obj}
            </ToggleButton>
        )
    });

    function cerrar(){
        let objCotiza = {
            id: infoCar.id,
            plazo: plazos,
            porcentaje: value,
            enganche: ((infoCar.precio) / 100) * value,
        }
        recalcularMensualidad(objCotiza);
        onHide();
    }

    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div style={calcularModal === true ? {color: 'white'} : {color: 'black'}}>

                    <Row className="mt-2 mb-2">
                        <Form.Label>Plazo a {plazos} meses</Form.Label>
                        <ButtonGroup>
                            {mappingMeses}
                        </ButtonGroup>
                    </Row>

                    <Row className={calcularModal === true ? "mt-2 mb-4" : "mt-2 mb-2"}>
                        <Form.Label>Enganche del {value}%</Form.Label>
                        <Col lg={8}>
                            <RangeSlider step={1} min={10} max={60} value={value} onChange={changeEvent => {setValue(changeEvent.target.value)}}/>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3 bg-white modal-cotizador-divPrecio-3pasos">
                                $<NumericFormat displayType="text" value={((infoCar.precio) / 100) * value} allowLeadingZeros thousandSeparator="," />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={calcularModal === true ? {display: 'flex', justifyContent: 'flex-start'} : {display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => cerrar()}>
                                Calcular
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )

}