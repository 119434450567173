import React, { useState, useCallback, useRef, useEffect } from "react";
import { Tabs, TabContainer, Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../../actions/riveroApi";
import { navigate } from "gatsby";

import WhatsApp from "../../../images/icons/whatsapp-alt-0.png";
import Llamada from "../../../images/icons/phone-alt-0.png";
import Mail from "../../../images/icons/mail-alt-0.png";

export default function FormularioVentaSalesforce(props) {
  console.log(props,"ESTAMOS EN FORMS NISSAN")

  const { children } = props;
  const [selected, setSelected] = useState("WhatsApp");
  const [loading, setLoading] = useState(0);
  const [whatsapp, setWhatsApp] = useState("");
  const [estado, setEstado] = useState(false);
  const [pagina, setPagina] = useState(props.pagina);
  const [cid, setCid] = useState("GRUPO SITIO -COTIZACIÓN");
  const [udid, setUdid] = useState("");
  const [validationToken, setValidationToken] = useState("");
  const recaptchaRef = useRef();

  const [campos, setCampos] = useState({
    Nombre: "",
    Apellido1: "",
    Apellido2: "",
    Contacto: "",
    TipoContacto: selected,
    Marca: "Nissan",
    Modelo: props.infoCar.model[0].toUpperCase() + props.infoCar.model.substring(1),
    Year: props.infoCar.year,
    SucursalId: "531",
    Sucursal: "RIVERO CONTRY",
    New: "new",
    Origin: cid,
    Url: props.props.location.href
  })

  console.log(props);

  if (loading == 0) {
    var parametros = props.props.location.search;

  if (parametros) {
      parametros = parametros.replace("?", "");
      parametros = parametros.split("&");
      console.log(parametros, "Paramtros url");
      for (var i = 0; i < parametros.length; i++) {
        var valores = parametros[i].split("=");
        if (valores[0] == "cid") {
          setCid(valores[1]);
          console.log('TENGO CID', valores[1])
        } else {
          setUdid(valores[1]);
          if (valores[0] == "udid") {
            setPagina(props.pagina + "-" + valores[1]);
          }
        }
      }
    } else {
      //props.props.location.search = "?cid=GRUPO SITIO -COTIZACIÓN";
      setCid("GRUPO SITIO -COTIZACIÓN");
    }
      setLoading(1);
  }
  //console.log("Contiene cid: ", cid);
  function handleKeyPress(event) {
    setWhatsApp(event.target.value);
  }

  const handleVerify = useCallback(
    (token) => {
      setValidationToken(token);
    },
    [setValidationToken]
  );

  console.log(props.props.location.search.substring(5),"Le campes")

  function sendEmail(data) {
    console.log("Nuevo Data: ", data);
    if(validationToken === "" || validationToken === null ){
      alert("Error en el captcha");
      console.log("ESTA VACIO EL CAPTCH", campos.Nombre);  
    } else if (campos.Nombre == "" || campos.Contacto == ""){
      console.log("Favor de llenar los campos de contact");
    } else {
      console.log("SE ENVIA CORREO");

          api.sendEmailLeads(data).then((resp) => {
              console.log("Recibo: ", resp.adfresponse.adfstatus);

              if (resp.adfresponse.adfstatus =="OK"){
                console.log('OK')
                navigate("/gracias-por-contactarnos/flotillas");
                
              } else {
                alert('Ocurrió un problema intentelo más tarde');
              }

          }).catch((error) => console.log(error));
    }

  }

  function postLead() {

    console.log(campos);
    switch(campos.SucursalId){
      case '531':
      campos.Sucursal = 'RIVERO CONTRY';
      break;
      case '532':
        campos.Sucursal = 'RIVERO LAS TORRES';
      break;
      case '533':
        campos.Sucursal = 'RIVERO VALLE';
      break;
    }

    console.log("Nuevo Data: ", campos);
    if(validationToken === "" || validationToken === null ){
      console.log("ESTA VACIO EL CAPTCH", campos.Nombre);  
      alert("Esta Vacio el CAPTCH")
    } else if (campos.Nombre == "" || campos.Contacto == ""){
      console.log("Favor de llenar los campos de contacto");
    } else {
      console.log("SE ENVIA CORREO");
      setEstado(true);

        api.postLeadNissan(campos).then((resp) => {
          console.log("Recibo: ", resp.adfresponse.adfstatus);

          if (resp.adfresponse.adfstatus =="OK"){
            console.log('OK')
            navigate("/gracias-por-contactarnos/detalle-de-nuevos");
            
          } else {
            alert('Ocurrió un problema intentelo más tarde');
            setEstado(false);
          }

        }).catch((error) => console.log(error));
    }
  
  }

  return (
    <>
        {children}
        <div className={props.componente === "3pasos" ? "text-white form-group" : "form-group"} hidden>
          <label htmlFor="first_name">Modelo</label>
          <input
            readOnly
            className="form-control"
            size="20"
            type="text"
            value={props.infoCar.model[0].toUpperCase() + props.infoCar.model.substring(1) + ' - ' + props.year}
          />
        </div>

          <div className={props.componente === "3pasos" ? "text-white form-group" : "form-group"}>
            <label htmlFor="agency">¿Agencia más cercana?</label>
            <select className="form-control" title="Agencia" onChange={(e) => setCampos({...campos, SucursalId: e.target.value})}>

              <option value="531">Contry</option>
              <option value="532">Las Torres</option>
              <option value="533">Valle</option>

            </select>
          </div>

        <div className={props.componente === "3pasos" ? "text-white form-group" : "form-group"}>
          <label htmlFor="first_name">Nombre</label>
          <input
            required
            className="form-control"
            maxLength={50}
            name="first_name"
            size="20"
            type="text"
            placeholder="Nombre"
            onKeyUp={(e) => setCampos({...campos, Nombre: e.target.value})}
            onClick={(a) => setCampos({...campos, Origin: cid})}
          />
        </div>

        <div className={props.componente === "3pasos" ? "text-white form-group" : "form-group"}>

            <label htmlFor="last_name">Apellidos</label>

            <div className="d-flex">

            <input
                required
                className="form-control"
                maxLength={50}
                name="last_name"
                size="10"
                type="text"
                placeholder="Primer Apellido"
                onKeyUp={(e) => setCampos({...campos, Apellido1: e.target.value})}
                style={{width: '250px', marginRight: '5px'}}>
            </input>

            <input
                required
                className="form-control"
                maxLength={50}
                name="last_name"
                size="10"
                type="text"
                placeholder="Segundo Apellido"
                onKeyUp={(e) => setCampos({...campos, Apellido2: e.target.value})}
                style={{width: '250px'}}>
            </input>
            
            </div>

          </div>    

        <div className={props.componente === "3pasos" ? "text-white form-group" : "form-group"}>
          <label htmlFor="contact-data">¿Cómo deseas que te contactemos?</label>

          <Tabs className="nav-fill m-0 tabs-nuevo">
            <TabContainer
              eventKey="WhatsApp"
              className="bg-white"
              title={
                <div
                  className="d-flex align-items-center justify-content-center"
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelected("WhatsApp")}
                  onKeyDown={() => setSelected("WhatsApp")}
                >
                  <img
                    src={WhatsApp}
                    className="mr-1"
                    style={{ width: 20 }}
                    alt="WhatsApp chevrolet rivero linda vista"
                  />
                  <span
                    className={`text-primary ml-1 ${
                      selected === "WhatsApp" ? `` : `hidden`
                    }`}
                  >
                    WhatsApp
                  </span>
                </div>
              }
            >
              <div className="border-left border-right border-bottom rounded-bottom w-100 p-3 pb-2">
                <input
                  id="contact-data"
                  type="tel"
                  className="form-control"
                  placeholder="WhatsApp a 10 dígitos"
                  onKeyUp={(e) => setCampos({...campos, Contacto: e.target.value , TipoContacto: "WhatsApp"})}
                  pattern="[0-9]{10}"
                  maxLength={10}
                  required={selected === "WhatsApp"}
                />
              </div>
            </TabContainer>

            <TabContainer
              eventKey="Llamada"
              className="bg-white"
              title={
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => setSelected("Llamada")}
                  onKeyDown={() => setSelected("Llamada")}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    src={Llamada}
                    className="mr-1"
                    style={{ width: 20 }}
                    alt="Telefono chevrolet rivero linda vista"
                  />
                  <span
                    className={`text-primary ml-1 ${
                      selected === "Llamada" ? `` : `hidden`
                    }`}
                  >
                    Llamada
                  </span>
                </div>
              }
            >
              <div className="border-left border-right border-bottom rounded-bottom w-100 p-3 pb-2">
                <input
                  id="contact-data"
                  type="tel"
                  className="form-control"
                  placeholder="Teléfono a 10 dígitos"
                  onKeyUp={(e) => setCampos({...campos, Contacto: e.target.value , TipoContacto: "Telefono"})}
                  pattern="[0-9]{10}"
                  required={selected === "Llamada"}
                />
              </div>
            </TabContainer>

            <TabContainer
              eventKey="Email"
              className="bg-white"
              title={
                <div
                  className="d-flex align-items-center justify-content-center"
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelected("Email")}
                  onKeyDown={() => setSelected("Email")}
                >
                  <img
                    src={Mail}
                    className="mr-1"
                    style={{ width: 20 }}
                    alt="Correo chevrolet rivero linda vista"
                  />
                  <span
                    className={`text-primary ml-1 ${
                      selected === "Email" ? `` : `hidden`
                    }`}
                  >
                    Correo
                  </span>
                </div>
              }
            >
              <div className="border-left border-right border-bottom rounded-bottom w-100 p-3 pb-2">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="contact-data"
                  aria-describedby="emailHelp"
                  placeholder="ejemplo@gruporivero.com"
                  onKeyUp={(e) => setCampos({...campos, Contacto: e.target.value, TipoContacto: "Email"})}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required={selected === "Email"}
                />
              </div>
            </TabContainer>
          </Tabs>

        </div>

        <div className="form-group">
          <small className={props.componente === "3pasos" ? "text-white form-text " : "form-text text-muted"}>
            * Nunca compartiremos tus datos con nadie más.
          </small>
        </div>

        <div className="form-group">
          <center>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le57eEaAAAAAFaBiKOIhLvbDBAXFqoJacXuJ4P3"
              onChange={handleVerify}
            />
          </center>
        </div>

        {estado == true ? (
        <div>
          <center>
            <p id="alertaAsesor">Contactando Asesor...</p>
          </center>
        </div>
        ) : (
        <div>
          <center>
            <input
              type="submit"
              className="boton boton-primario"
              style={{ width: "80%" }}
              value={props.titulo}
              onClick={postLead}
              id="btn-Enviar-Form"
            />
          </center>
        </div>
        )}

    </>
  );
}